.app-metrics-card {
  border: none;
  background-color: #F9EE51;
  color: #272D3E;
  border-radius: 20px;
  box-shadow: 0 30px 60px rgba(249, 238, 81, 0.4);
  position: absolute;
  width: 100%;
  top: 47%;

  p {
    &:nth-child(1) {
      font-weight: 700;
      font-size: 1.875rem;
      line-height: 39px;
    }

    &:nth-child(2) {
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 18px;
    }
  }
}