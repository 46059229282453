.testimonials {
  min-height: 52vw;
  background-image: url("../../images/home/double-angle-dark-bg.png");
  background-size: cover;
  background-position-x: center;
  background-repeat: no-repeat;

  @include media-breakpoint-down(md) {
    background-image: none;
    background-color: #272d3e;
    border-radius: 50px;
    min-height: auto;

    .pt-100{
      padding-top: 0;
    }
  }

  .section-content {
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 40px;
  }
}
