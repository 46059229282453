.react-multi-carousel-dot-list {
  .react-multi-carousel-dot {
    button {
      border: none;
      background-color: #E5E5E5;
    }

    &--active {
      button {
        background-color: #54B948;
      }
    }
  }
}

.carousel-card-list-lg {
  height: 450px;
}
