.navbar {
  .navbar-nav {
    width: 100%;
    a {
      font-size: .9rem !important;
      padding: 5px 12px!important;
      text-transform: uppercase;
      transition: all .3s;

      &:hover{
        transform: scale(1.1);
      }

      &.download-button {
        padding-top: 10px !important;
      }
    }
  }

  &-light {
    a {
      color: white !important;
    }
    a.active{
      border-bottom: 2px solid white;
      font-weight: bolder;
    }
  }

  &-dark {
    .navbar-brand {
      filter: brightness(0%);
    }

    a {
      color: #272D3E !important;
    }

    a.active{
      border-bottom: 2px solid black;
      font-weight: bolder;
    }
  }

  a.active:nth-child(1){
    border-bottom: none !important;
  }
}

#basic-navbar-nav.offcanvas {
  @include media-breakpoint-down(lg) {
    border-top-right-radius: 20px;
    padding-top: 20px;
    background-color: $dark;

    .navbar-nav {
      width: 100%;

      .nav-link {
        color: white;
        padding-left: 20px;
        padding-top: 20px;
        padding-bottom: 20px;
        font-size: 1rem;
        font-weight: bolder;
        border-bottom: 1px solid white;

        &:nth-child(1) {
          border-bottom: none;
          padding-bottom: 35px;
        }

        &:nth-child(8) {
          border-bottom: none;
        }
      }

      .offcanvas-logo{
        width: 220px;
      }
    }
  }
}
