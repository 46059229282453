.absolute-image {
  right: 25%;
  bottom: 0;
  width: 65%
}

.benefits-image {
  right: 25%;
  bottom: 20px;
  width: 65%;
  @include media-breakpoint-only(xl){
    bottom: 150px;
  }

  @include media-breakpoint-only(lg){
    bottom: 400px;
  }
}

.container-with-angle {
  clip-path: polygon(0 0, 100% 0, 100% 80%, 0% 100%);
  background-color: rgb(123 193 66 / 20%);
  @include media-breakpoint-down(md) {
    clip-path: none;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 0;
    position: relative;
    .partners-image-container {
      height: 320px;
      background: linear-gradient(
                      to bottom,
                      #e4f3d9 0%,
                      #e4f3d9 50%,
                      white 50%,
                      white 100%
      );

      img {
        position: absolute;
        top: -35px;
        left: -40px;
        width: 120%;
        max-width: none;
      }
    }

  }
}
