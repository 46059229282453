.members {
  &-info {
    text-align: left;

    h3 {
      font-weight: 700;
      font-size: 1.25rem;
      line-height: 120%;
    }

    p {
      font-weight: 500;
      font-size: 1.125rem;
      line-height: 150%;
    }
  }

  &-image {
    display: block;
    margin: 0 auto;
    width: 180px;
    height: 180px;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}