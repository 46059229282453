.navbar-light {
  .hvr-underline-from-center::before {
    background: white !important;
  }
}

.navbar-dark {
  .hvr-underline-from-center::before {
    background: black !important;
  }
}

.footer {
  .hvr-underline-from-center::before {
    background: white !important;
    height: 2px;
  }
}

.offcanvas {
  .hvr-underline-from-center::before {
    background: white !important;
  }
}
