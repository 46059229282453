.autoconectado-logos-container {
  //position: absolute;
  //top: 30rem;
  //left: 100px;
  //z-index: 9;
  @include media-breakpoint-down(lg) {
    display: none;
  }

  img {
    width: 120px;
  }
}

.autoconectado-header {
  @include media-breakpoint-down(lg) {
    height: 700px;
    margin-bottom: 0;
    .banner-home-text {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }
}

.autoconectado-header-image {
  top: 15rem;
  right: 50px;
}

.autoconectado-header-image-responsive {
  left: 0;
  right: 0;
  bottom: 0;
  top: auto;
  margin: 0 auto;
  width: 400px;
  position: absolute;
}
