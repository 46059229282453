.line-height- {
  &21 {
    line-height: 21px;
  }

  &28 {
    line-height: 28px;
  }

  &30 {
    line-height: 30px;
  }

  &32 {
    line-height: 32px;
  }

  &44 {
    line-height: 44px;
  }
}

.m {
  &t- {
    &200 {
      margin-top: 200px;
    }
  }
}

.p {
  &t- {
    &200 {
      padding-top: 200px;
    }

    &100 {
      padding-top: 100px;
    }
  }

  &b- {
    &200 {
      padding-bottom: 200px;
    }

    &100 {
      padding-bottom: 100px;
    }
  }
}

.pt-200 {
  padding-top: 200px;
}

.rounded-icon-container {
  width: 55px;
  height: 55px;
  background-color: rgba(47, 180, 74, 0.1);
  border-radius: 50%;
  text-align: center;
  color: $green-darker;

  i {
    padding-top: 19px !important;
  }
}

.text-darker-green {
  color: $green-darker;
}

.bg-gray {
  background-color: rgb(231, 231, 231);
}
.linear-gradient{
  &-white-to-grey{
    background: linear-gradient(0deg, rgb(231, 231, 231, 1) 0%, rgba(255,255,255,1) 50%);
  }
}
