.banner- {
  &home {
    .nav-link {
      color: white !important;
    }

    &-title {
      font-size: 3rem;
      line-height: 62.5px;
      font-family: 'DM Sans Bold', serif;
      font-weight: 500;

      @include media-breakpoint-down(md) {
        font-size: 2rem;
        line-height: 39.74px;
      }
    }

    &-text {
      font-size: 1rem;
      font-family: 'DM Sans Regular', serif;
      font-weight: lighter;

      @include media-breakpoint-down(md) {
        font-size: 0.875rem;
        line-height: 18.23px;
        width: 55%;
      }
    }

    &-image {
      position: absolute;
      right: 5rem;
      top: 6rem;
      width: 40rem;
      z-index: 9;

      @include media-breakpoint-down(lg) {
        height: auto;
        padding-bottom: 100px;
        top: 120px;
        width: 450px;
        right: -10.5rem;
      }
    }

    &-downloads-label {
      p:nth-child(1) {
        color: rgba(255, 255, 255, 0.6);
        font-size: 1rem;
        font-weight: 700;
      }

      p:nth-child(2) {
        color: #FFFFFF;
        font-size: 1.5rem;
        font-weight: 600;
      }
    }

    &-brands {
      a {
        width: 50px;
      }
    }

  }
}

.home-header {
  height: 1100px;
  min-height: auto;
  overflow: hidden;
  margin-bottom: -180px;

  @include media-breakpoint-down(lg) {
    height: auto;
    padding-bottom: 100px;
    margin-bottom: 50px;
  }

  @include media-breakpoint-down(md) {
    margin-bottom: 150px;
  }
}
