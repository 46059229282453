.brands-carousel {
  margin-bottom: -10rem;
  padding-bottom: 85px;

  .card {
    border-radius: 10px;
    border: none;
    box-shadow: 2px 43px 68px -43px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 2px 43px 68px -43px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 2px 43px 68px -43px rgba(0, 0, 0, 0.75);
  }
}