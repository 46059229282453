.accordion-container {
  .accordion- {
    &item {
      border: 1px solid #d2d2d2;
      border-radius: 10px;
    }

    &button {
      border-radius: 10px;
    }
  }

  .accordion-button:not(.collapsed) {
    color: #FFF !important;
    background-color: $green-darker !important;
    border-bottom-left-radius: unset;
    border-bottom-right-radius: unset;
  }

  .accordion-button:link, .accordion-button:visited, .accordion-button:hover, .accordion-button:active {
    background-color: $green-darker !important;
    color: #FFF !important;
    text-decoration: none !important;
    border: hidden #FFF !important;
    box-shadow: 0px !important;
  }

  .accordion-button:focus {
    z-index: 3;
    border-color: #FFF !important;
    outline: 0;
    box-shadow: 0 0 0 .25rem #FFF !important;
  }

  .accordion-collapse.show{
    background-color: $green-darker;
    color: #FFF !important;
  }
}