.security-image-container{
  min-height: 500px;
  width: 100%;
}
.security-image{
  @include media-breakpoint-down(md) {
    width: 700px;
    max-width: none;
    position: absolute;
    left: -60%;
    top: -50px;
  }
}
