* {
  font-family: 'DM Sans Regular', serif;
}

.main-header {
  min-height: 50vw;
  color: white;
  width: 100%;
  background-size: cover;
  background-position-x: center;
  background-repeat: no-repeat;
  background-position-y: -14vw;
}

.section-title {
  margin: 20px 0;
  text-align: left;

  font-size: 0.875rem;
  max-width: 600px;
  position: relative;
  padding-left: 55px;
  font-family: 'DM Sans Bold', serif;

  &:before {
    content: "";
    display: block;
    width: 40px;
    height: 3px;

    left: 0;
    top: 43%;
    position: absolute;
  }

  &-green {
    color: $green-darker;

    &:before {
      background: $green-darker;
    }
  }

  &-dark {
    color: $dark;

    &:before {
      background: $dark;
    }
  }

  &-white {
    color: $white;

    &:before {
      background: $white;
    }
  }
}

.section-title-alt {
  text-align: center;
  font-size: 0.875rem;
  font-family: 'DM Sans Bold', serif;
  border-radius: 100px;
  max-width: 180px;
  margin: 0 auto;
  padding: 15px 10px;
}

.title-alt- {
  &light {
    color: $green-darker;
    background-color: #FFFFFF;
  }

  &dark {
    color: #CDDD43;
    background-color: rgba(198, 244, 248, 0.1);
  }

  &lime {
    color: $green-darker;
    background-color: rgba(47, 180, 74, 0.1);;
  }
}

.section-subtitle {
  font-size: 2rem;
  font-family: 'DM Sans Bold', serif;
  font-weight: 500;
  line-height: 44px;
}

.section-content {
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 32px;
}

.card-with-shadow {
  border: none;
  background: #FFFFFF;
  box-shadow: 0 15px 30px rgba(2, 40, 43, 0.1);
  border-radius: 15px;

  @include media-breakpoint-down(xs) {
    .row {
      div:nth-child(1) {
        i {
          font-size: 6.25rem;
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    .row {
      div:nth-child(1) {
        i {
          font-size: 1.4rem;
        }
      }
    }
  }
}

.fa-check-circle-light {
  border: 1px solid $green-darker;
  border-radius: 50%;
  padding: 5px;
}

.rounded-button {
  border-radius: 100px;
  font-family: 'DM Sans Bold', serif;
  font-size: 1rem;

  @include media-breakpoint-down(md) {
    font-size: 0.75rem;
  }

  &-sm {
    padding: 10px 20px;
  }

  &-md {
    padding: 25px 45px;
    @include media-breakpoint-down(md) {
      padding: 10px 20px;
    }
  }

  &-green {
    box-shadow: 0 15px 30px rgba(163, 205, 58, 0.33);
    background-color: $success;
    color: #FFFFFF;

    &:hover, &:active, &:visited, &:focus {
      background-color: fade-out($success, .1);
      border: none;
    }
  }

  &-light {
    box-shadow: #FFFFFF;
    background-color: #FFFFFF;
  }
}

.rounded-container {
  border-radius: 20px;

  &-yellow {
    background-color: rgb(249 238 81 / 30%);
  }

  &-lime {
    background-color: rgb(205 221 67 / 30%);
  }

  &-green {
    background-color: rgb(163 205 58 / 30%);
  }

  &-gray {
    background-color: rgb(196 196 196 / 30%);
  }
}

.carousel-container {
  width: 120%;
  margin-left: -10%;

  .react-multi-carousel-list {
    .card {
      background-color: transparent;
      border: none;

      &-tall {
        min-height: 270px;
      }

      &-body {
        background-color: #FFFFFF;
        border-radius: 10px;
        box-shadow: 2px 5px 29px -20px rgba(0, 0, 0, 0.75);
        -webkit-box-shadow: 2px 5px 29px -20px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 2px 5px 29px -20px rgba(0, 0, 0, 0.75);
        padding: 30px 0;

        i {
          font-size: .8rem;
        }

        &-title {
          font-weight: 500;
          font-size: 1rem;
          line-height: 21px;
          color: $green-darker;
          font-family: 'DM Sans Bold', serif;
        }

        &-subtitle {
          font-weight: 500;
          font-size: 2rem;
          line-height: 42px;
        }

        &-description {
          font-weight: 400;
          font-size: 1rem;
          line-height: 20px;
        }

        .card-text {
          text-align: center;
          font-weight: 700;
          font-size: 1rem;
        }
      }
    }

    .react-multiple-carousel__arrow {
      background-color: $success;

      &--left {
        left: calc(0% + 1px) !important;
      }

      &--right {
        right: calc(0% + 1px) !important;
      }
    }

  }
}

.clip-path-bg {
  min-height: 600px;
  width: 100%;
  clip-path: polygon(50% 0%, 100% 0, 100% 41%, 100% 45%, 99% 49%, 97% 52%, 94% 54%, 0% 70%, 0% 35%, 0 0);
  margin-bottom: -140px;

  &-dark {
    background: linear-gradient(180deg, #2F364B 0%, #262B3D 85.25%);

    .container {
      color: $white !important;
    }
  }

  &-yellow {
    background: #F9EE51;
  }

  &-lime {
    background: #CDDD43;
  }

  &-light-green {
    background: #A3CD3A;
  }

  &-green {
    background: #7BC142;
  }
}

@include media-breakpoint-down(lg) {
  .clip-path-bg {
    clip-path: none;
    border-radius: 0 0 30px 30px;
    position: relative;
    height: auto;
    min-height: auto;
    margin-bottom: auto;
  }
}

.custom-styled-form, .newsletter-form {
  .input-group {
    margin-bottom: 50px;

    input, textarea {
      font-weight: 400;
      font-size: 1rem;
      line-height: 160%;
      border: none;
      border-bottom: 1px solid #737B7D;
      border-radius: unset;
      padding: 20px 10px;
    }

    textarea {
      height: 100px;
    }

    .text-danger {
      color: $danger;
      margin-top: 5px;
      font-style: italic;
    }
  }

}

